import React, { ReactElement } from 'react';
import { Set } from 'immutable';
import { Order } from 'mapado-ticketing-js-sdk';
import { SeatConfigType } from '../propTypes';
import SelectSeatCaption from './Caption/SelectSeatCaption';
import OrderTile from './OrderReplacement/OrderTile';
import { getAvailableSeatListFromOrder } from '../utils/memoized';

function OrderSidebar({
  seatConfig,
  selectedSeatIdSet,
  order,
}: Props): ReactElement {
  return (
    <div className="mpd-seating__order-sidebar">
      <div className="replacement-sidebar__content">
        <div className="replacement-sidebar__name">{seatConfig.label}</div>
        <hr />
        <div>
          <SelectSeatCaption
            cartOrOrder={order}
            selectedSeatIdSet={selectedSeatIdSet}
            nbSeatsInBooking={getAvailableSeatListFromOrder(order).size}
          />
        </div>

        <table className="mpd-table mpd-table--narrow mt0">
          <tbody>
            <OrderTile order={order} />
          </tbody>
        </table>
      </div>
    </div>
  );
}

type OrderSidebarProps = {
  selectedSeatIdSet: Set<number> | null;
  seatConfig: SeatConfigType;
};

type StateProps = {
  order: Order;
};

type Props = OrderSidebarProps & StateProps;

export default OrderSidebar;
