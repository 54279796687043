import React from 'react';
import { RootState } from '../../../reducers';
import { useDrawingContext } from '../../../contexts/DrawingContext';
import { useSeatingSelector } from '../../../reducers/typedFunctions';
import { useTranslation } from '../../../i18n';

function GaugeSidebarContent() {
  const { t } = useTranslation();
  const { isDrawing } = useDrawingContext();
  const selectedSeatIdSet = useSeatingSelector((state: RootState) =>
    state.seating.get('selectedSeatIdSet')
  );
  const displaySelection =
    isDrawing && selectedSeatIdSet && selectedSeatIdSet.size > 0;

  return (
    <div>
      <h3 className="mpd-seating-sidebar-header__title txtcenter">
        {t(
          displaySelection
            ? 'seating.gauge.sidebar.selection'
            : 'seating.gauge.sidebar.title'
        )}
      </h3>
      {!displaySelection && (
        <div className="stock-management-sidebar__help">
          {t('seating.gauge.sidebar.help')}
        </div>
      )}
      {displaySelection && (
        <p className="txtcenter">
          {t('seating.move_seat.selected_seats', {
            count: selectedSeatIdSet.size,
          })}
        </p>
      )}
    </div>
  );
}

export default GaugeSidebarContent;
