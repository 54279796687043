import React, { ReactElement } from 'react';
import {
  Cart,
  CartItem,
  Order,
  Ticket,
  assertRelationIsListOfString,
  assertRelationIsObject,
  assertRelationIsString,
} from 'mapado-ticketing-js-sdk';
import { getCustomerFullname, getShortId } from '@mapado/js-component';
import { Set } from 'immutable';
import { useTranslation } from '../../i18n';
import { isCart, isOrder } from '../../utils/booking';

export default function SelectSeatCaption({
  selectedSeatIdSet,
  nbSeatsInBooking,
  cartOrOrder,
}: SelectSeatCaptionTypes): ReactElement {
  const { t } = useTranslation();

  const nbSelectedSeat = (selectedSeatIdSet && selectedSeatIdSet.size) || 0;
  let participant = null;

  const getParticipantFromTicketOrCartItem = (item: Ticket | CartItem) => {
    if (item.participant) {
      assertRelationIsObject(item.participant, 'item.participant');
      participant = getCustomerFullname(item.participant);
    }
  };

  if (nbSelectedSeat === 1) {
    const firstSelectedSeatId = selectedSeatIdSet
      ?.values()
      .next()
      .value.toString();

    if (isOrder(cartOrOrder)) {
      const foundTicket = cartOrOrder?.ticketList?.find((ticket) => {
        assertRelationIsObject(ticket, 'ticket');

        if (!ticket.availableSeat) {
          return false;
        }

        assertRelationIsObject(ticket.availableSeat, 'ticket.availableSeat');
        assertRelationIsObject(
          ticket.availableSeat.seat,
          'ticket.availableSeat.seat'
        );
        assertRelationIsString(
          ticket.availableSeat.seat['@id'],
          'ticket.availableSeat.seat.id'
        );

        const orderItemSeatId = getShortId(ticket.availableSeat.seat['@id']);

        return orderItemSeatId === firstSelectedSeatId;
      });

      if (foundTicket) {
        assertRelationIsObject(foundTicket, 'foundTicket');
        getParticipantFromTicketOrCartItem(foundTicket);
      }
    } else if (isCart(cartOrOrder)) {
      const foundCartItem = cartOrOrder?.cartItemList?.find((cartItem) => {
        assertRelationIsObject(cartItem, 'cartItem');

        if (!cartItem.seatList || cartItem.seatList.size === 0) {
          return false;
        }

        assertRelationIsListOfString(cartItem.seatList, 'cartItem.seatList');
        assertRelationIsString(
          cartItem.seatList.first(),
          'cartItem.seatList.first'
        );

        const cartItemSeatId = getShortId(cartItem.seatList.first());

        return cartItemSeatId === firstSelectedSeatId;
      });

      if (foundCartItem) {
        assertRelationIsObject(foundCartItem, 'foundCartItem');
        getParticipantFromTicketOrCartItem(foundCartItem);
      }
    }
  }

  return (
    <div className="replacement-sidebar__replacement-caption">
      <div className="mb3">
        <div className="larger">
          {t('seating.move_seat.selected_seats', {
            count: nbSelectedSeat,
          })}
        </div>
        {participant && (
          <div className="mpd-color-gray mt1 mb1">{participant}</div>
        )}
        <div className="smaller">
          {t('seating.move_seats.nb_seats_in_order', {
            count: nbSeatsInBooking,
          })}
        </div>
      </div>
      {nbSelectedSeat > 0 && (
        <p className="small mt0">
          {t('seating.help_move_seats', { count: nbSelectedSeat })}
        </p>
      )}
    </div>
  );
}

type SelectSeatCaptionTypes = {
  selectedSeatIdSet: Set<number> | null;
  nbSeatsInBooking: number;
  cartOrOrder: Order | Cart;
};
