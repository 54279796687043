import { List, Set } from 'immutable';
import {
  Booking,
  AvailableSeat,
  assertRelationIsDefined,
  assertRelationIsString,
  assertRelationIsObject,
  AvailableSeatBulkRequestMember,
  AvailableSeatStatus,
  Contingent,
  AVAILABLE_SEAT_STATUS_AVAILABLE,
  AVAILABLE_SEAT_STATUS_DISMISSED,
  Cart,
  PagedCollection,
  Order,
  createEntity,
} from 'mapado-ticketing-js-sdk';
import { getShortId } from '@mapado/js-component';
import { MpdToastFunction, TOAST_TYPE } from '@mapado/makeup';
import TicketingSdkInstance from '../TicketingSdkInstance';
import getAvailableSeatListForEventDate from './getAvailableSeatListForEventDate';
import {
  BOOKING_STATUS_AVAILABLE,
  BOOKING_STATUS_DISMISSED,
  BOOKING_STATUS_LIST,
} from '../utils/booking';
import { getAvailableSeatListFromBooking } from '../utils/seatSelectable';
import { getPossibleSeatGroupListForBookedSeat } from '../utils/memoized';
import {
  SET_AVAILABLE_SEAT_STATUS,
  REQUEST_MOVE_SEAT_LIST,
  MOVE_SEAT_LIST_FAILURE,
  MOVE_SEAT_LIST_SUCCESS,
  FOCUS_BOOKING,
  UNFOCUS_BOOKING,
  SET_AVAILABLE_SEAT_LIST,
  SELECT_SEAT_LIST,
  INIT_CLEANUP_STATE,
  SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST,
  ADD_SEAT_GROUP,
  ADD_CONTINGENT,
  REMOVE_CONTINGENT,
  UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP,
  SET_AVAILABLE_SEAT_LIST_AVAILABLE,
} from '../reducers/types';
import type { SeatActionTypes, SeatThunkAction } from '../reducers/SeatReducer';
import type {
  AvailableSeatType,
  NoGroupSeatType,
  SeatGroupType,
  SeatType,
  UpdateAvailableSeatListSeatGroupType,
} from '../propTypes';
import {
  availableSeatSelector,
  findAvailableSeatForSeatId,
  selectedSeatIdSetSelector,
} from '../utils/selectors';
import { SEAT_FIELDS } from './fields';
import {
  getSeatIdForAvailableSeat,
  getSeatLongIdForAvailableSeat,
} from '../utils/entity';
import type { ReplaceAvailableSeatFunction } from '../components/useCart';
import { TFunction } from '../i18n';

type FilteredAvailableSeat = {
  '@id': null | string;
  '@type': 'AvailableSeat';
  seat: null | string;
  status: AvailableSeatStatus;
};

export function resetState(): SeatActionTypes {
  return {
    type: INIT_CLEANUP_STATE,
  };
}

function findAvailableSeatFromBookingCollectionSeatId(
  availableSeatList: List<AvailableSeat>,
  seatId: number
): AvailableSeat | null {
  const availableSeat = availableSeatList.find((avs) => {
    assertRelationIsObject(avs.seat, 'avs.seat');

    const avsSeatId = avs.seat['@id'];

    return avsSeatId === `/v1/seats/${seatId}`;
  });

  return availableSeat || null;
}

export function setAvailableSeatListAvailable(
  availableSeatList: List<AvailableSeat>
): SeatActionTypes {
  return {
    type: SET_AVAILABLE_SEAT_LIST_AVAILABLE,
    availableSeatList,
  };
}

function setAvailableSeatStatus(
  seatId: number,
  bookingStatus: BOOKING_STATUS_LIST
): SeatActionTypes {
  return {
    type: SET_AVAILABLE_SEAT_STATUS,
    seatId,
    bookingStatus,
  };
}

/**
 * TODO : originSeatIdList and destinationSeatIdList should not be array, as only have a single item each time
 */
function moveSeat(
  cartOrOrder: Cart | Order,
  originSeatId: number,
  destinationSeatId: number,
  targetSeatList: SeatType,
  replaceAvailableSeat: ReplaceAvailableSeatFunction,
  errorCallback: () => void,
  toast: MpdToastFunction,
  t: TFunction
): SeatThunkAction {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch) => {
    dispatch({
      type: REQUEST_MOVE_SEAT_LIST,
    });

    const availableSeatList = getAvailableSeatListFromBooking(cartOrOrder);

    // find and update the AvailableSeat to move
    const availableSeat: AvailableSeat | null =
      findAvailableSeatFromBookingCollectionSeatId(
        availableSeatList,
        originSeatId
      );

    if (!availableSeat) {
      throw new Error(
        `Unable to move seat with id ${originSeatId} as it is not found in the current state.`
      );
    }

    // we force the seat to be a string
    // return found.set('seat', `/v1/seats/${destinationSeatId}`);

    const availableSeatToMove: AvailableSeatBulkRequestMember = {
      '@id': availableSeat['@id'],
      '@type': 'AvailableSeat',
      seat: `/v1/seats/${destinationSeatId}`,
      status: availableSeat.status,
    };

    return TicketingSdkInstance.getSdk()
      .getRepository('availableSeat')
      .putBulk({ 'hydra:member': [availableSeatToMove] }, [
        { seatGroup: ['@id', 'label'] },
        { seat: SEAT_FIELDS },
      ])
      .then((response: Response) => response.json())
      .then((body: { 'hydra:member': AvailableSeat[] }) => {
        assertRelationIsObject(
          body['hydra:member'][0],
          "body['hydra:member'][0]"
        );

        // get the first item, as we only "PUT" one item in the bulk request
        const avs = body['hydra:member'][0];

        assertRelationIsObject(avs.seat, "body['hydra:member'][0].seat");
        assertRelationIsString(
          avs.seat['@id'],
          "body['hydra:member'][0].seat['@id']"
        );

        const actualDestinationSeatId: number = parseInt(
          getShortId(avs.seat['@id']),
          10
        );

        dispatch({
          type: MOVE_SEAT_LIST_SUCCESS,
          targetSeatList: List([targetSeatList]),
          originSeatIdList: List([originSeatId]),
          destinationSeatIdList: List([actualDestinationSeatId]),
        });

        if (destinationSeatId === actualDestinationSeatId) {
          assertRelationIsObject(avs.seatGroup, 'avs.seatGroup');
          // inform cart or order that they need to replace the given availableSeat
          replaceAvailableSeat(
            availableSeat,
            availableSeat
              .set('seat', createEntity(avs.seat))
              .set('seatGroup', createEntity(avs.seatGroup))
          );
          toast({
            title: t('move_seat.moved.success'),
            type: TOAST_TYPE.SUCCESS,
          });
        } else {
          errorCallback();
          toast({
            title: t('move_seat.moved.error'),
            type: TOAST_TYPE.ERROR,
          });
        }
      })
      .catch((err: Error) => {
        dispatch({
          type: MOVE_SEAT_LIST_FAILURE,
        });
        // eslint-disable-next-line no-console
        console.error(err);
        errorCallback();

        toast({
          title: t('move_seat.moved.error'),
          type: TOAST_TYPE.ERROR,
        });
      });
  };
}

export function selectBatchOfSeats(
  rawSelectedSeatIdSet: number[] | Set<number>
): SeatThunkAction<SeatActionTypes> {
  return (dispatch, getState) => {
    const state = getState();
    const stateSeatList = state.seating.get('seatList');
    const seatList: NoGroupSeatType[] = [];

    const selectedSeatIdSet: Set<number> = Set(rawSelectedSeatIdSet);

    if (selectedSeatIdSet.size > 0) {
      (stateSeatList || List()).forEach((seat) => {
        const shortId = Number(seat['@id'].replace('/v1/seats/', ''));

        if (selectedSeatIdSet.has(shortId)) {
          seatList.push(seat);
        }
      });
    }

    return dispatch({
      type: SELECT_SEAT_LIST,
      seatList: List(seatList),
    });
  };
}

export function resetSelectedSeats(): SeatThunkAction {
  return (dispatch) => {
    dispatch(selectBatchOfSeats(Set()));
  };
}

export function addSeatToSelectedSeats(seatId: number): SeatThunkAction {
  return (dispatch, getState) => {
    const selectedSeatIdSet = selectedSeatIdSetSelector(getState()) ?? Set();

    dispatch(selectBatchOfSeats(selectedSeatIdSet.add(seatId)));
  };
}

export function removeSeatFromSelectedSeats(seatId: number): SeatThunkAction {
  return (dispatch, getState) => {
    const selectedSeatIdSet = selectedSeatIdSetSelector(getState()) ?? Set();

    dispatch(selectBatchOfSeats(selectedSeatIdSet.remove(seatId)));
  };
}

export function initBatchOfSeatsFromSeatList(
  seatList: List<NoGroupSeatType>
): SeatThunkAction {
  return (dispatch) =>
    dispatch({
      type: SELECT_SEAT_LIST,
      seatList,
    });
}

export function selectBatchOfAvailableSeats(
  selectedAvailableSeatIdList: List<string>
): SeatThunkAction {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch, getState) => {
    const state = getState();

    const availableSeatList = availableSeatSelector(state);
    const seatIdList = (availableSeatList || List())
      .map((availableSeat) => {
        if (
          selectedAvailableSeatIdList.contains(availableSeat['@id']) &&
          availableSeat.seat !== null
        ) {
          return getSeatIdForAvailableSeat(availableSeat);
        }

        return null;
      })
      .filter((seatId: number | null): seatId is number => seatId !== null)
      .toSet();

    return dispatch(selectBatchOfSeats(seatIdList));
  };
}

export function selectBatchOfAvailableSeatModels(
  selectedAvailableSeatModelIdList: List<string>
): SeatThunkAction {
  return (dispatch, getState) => {
    const state = getState();

    const availableSeatModelList = availableSeatSelector(state);
    const seatIdList = (availableSeatModelList || List())
      .map((availableSeatModel) => {
        if (
          selectedAvailableSeatModelIdList.contains(
            availableSeatModel['@id']
          ) &&
          availableSeatModel.seat !== null
        ) {
          assertRelationIsObject(availableSeatModel.seat, 'availableSeat.seat');

          return Number(
            availableSeatModel.seat['@id'].replace('/v1/seats/', '')
          );
        }

        return null;
      })
      .filter((seatId: number | null): seatId is number => seatId !== null)
      .toSet();

    return dispatch(selectBatchOfSeats(seatIdList));
  };
}

export function selectOrMoveSeat(
  cartOrOrder: Cart | Order,
  seatId: number,
  isSelectable: boolean,
  isCurrentBooking: boolean,
  limitSeatGroupToThisCart: Cart | null, // this is used ONLY on OrderViewer context to avoid moving cart items to unallowed seat groups
  replaceAvailableSeat: ReplaceAvailableSeatFunction,
  errorCallback: () => void,
  toast: MpdToastFunction,
  t: TFunction
): SeatThunkAction {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch, getState) => {
    const state = getState();
    const selectedSeatIdSet = state.seating.get('selectedSeatIdSet') ?? Set();

    if (!isSelectable) {
      return Promise.resolve();
    }

    // Add or remove seat from selection if selected a availableSeat
    if (isCurrentBooking) {
      const hasSeatId = selectedSeatIdSet?.has(seatId) || false;

      if (hasSeatId) {
        // remove from selected
        const nextSelection = selectedSeatIdSet.delete(seatId);

        return dispatch(selectBatchOfSeats(nextSelection));
      }

      // add to selection
      const nextSelection = selectedSeatIdSet.add(seatId);

      return dispatch(selectBatchOfSeats(nextSelection));
    }

    let movingSeatId = null;

    const targetAvailableSeat = findAvailableSeatForSeatId(
      getState(),
      `/v1/seats/${seatId}`
    );

    if (!limitSeatGroupToThisCart) {
      movingSeatId = selectedSeatIdSet.first();
    } else {
      // this is used ONLY on OrderViewer context
      assertRelationIsObject(targetAvailableSeat, 'targetAvailableSeat');
      assertRelationIsObject(
        targetAvailableSeat.seatGroup,
        'targetAvailableSeat.seatGroup'
      );

      // proxy for now, but `getPossibleSeatGroupListForBookedSeat` should only handle carts
      // there is another call in `seatSelectable` for now (but only used in OrderViewer context too)
      const bookingCollection = new PagedCollection<Booking>({
        'hydra:member': [],
      }).setIn(
        ['hydra:member', 0],
        /** @ts-expect-error -- @see https://github.com/mapado/ticketing-js-sdk/issues/250  */
        new Booking({
          cart: limitSeatGroupToThisCart,
          order: null,
        })
      );

      const destinationSeatGroup = targetAvailableSeat.seatGroup;

      movingSeatId = selectedSeatIdSet.find((selectedSeatId) => {
        const possibleSeatGroupListForCartItem =
          getPossibleSeatGroupListForBookedSeat(
            bookingCollection,
            Set([`/v1/seats/${selectedSeatId}`])
          );

        return !!(
          destinationSeatGroup &&
          possibleSeatGroupListForCartItem.indexOf(
            destinationSeatGroup['@id']
          ) >= 0
        );
      });
    }

    if (!movingSeatId) {
      toast({
        title: t('cart_replacement.no_moving_seat_message'),
        type: TOAST_TYPE.ERROR,
      });

      return Promise.resolve();
    }

    assertRelationIsObject(
      targetAvailableSeat.seat,
      'targetAvailableSeat.seat'
    );

    return dispatch(
      moveSeat(
        cartOrOrder,
        movingSeatId,
        seatId,
        targetAvailableSeat.seat,
        replaceAvailableSeat,
        errorCallback,
        toast,
        t
      )
    );
  };
}

// we use void here as this type is used in props, and the container is set between
export type SelectOrMoveSeatAction = (
  ...params: Parameters<typeof selectOrMoveSeat>
) => void;

export function dismissOrRestore(
  availableSeat: AvailableSeatType,
  errorCallback: () => void
): SeatThunkAction {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch) => {
    const seatLongId = getSeatLongIdForAvailableSeat(availableSeat);
    const seatId = Number(getShortId(seatLongId));

    const { bookingStatus } = availableSeat;

    switch (bookingStatus) {
      case BOOKING_STATUS_DISMISSED:
        return TicketingSdkInstance.getSdk()
          .getRepository('availableSeat')
          .putBulk({
            'hydra:member': [
              {
                '@id': availableSeat['@id'],
                '@type': 'AvailableSeat',
                seat: seatLongId,
                status: AVAILABLE_SEAT_STATUS_AVAILABLE,
              },
            ],
          })
          .then((response: Response) => {
            if (response.status === 200) {
              return dispatch(
                setAvailableSeatStatus(seatId, BOOKING_STATUS_AVAILABLE)
              );
            }

            return null;
          })
          .catch((err: Error) => {
            // eslint-disable-next-line no-console
            console.error(err);
            errorCallback();
          });
      default:
        return TicketingSdkInstance.getSdk()
          .getRepository('availableSeat')
          .putBulk({
            'hydra:member': [
              {
                '@id': availableSeat['@id'],
                '@type': 'AvailableSeat',
                seat: seatLongId,
                status: AVAILABLE_SEAT_STATUS_DISMISSED,
              },
            ],
          })
          .then((response: Response) => {
            if (response.status === 200) {
              return dispatch(
                setAvailableSeatStatus(seatId, BOOKING_STATUS_DISMISSED)
              );
            }

            return null;
          })
          .catch((err: Error) => {
            // eslint-disable-next-line no-console
            console.error(err);
            errorCallback();
          });
    }
  };
}

export function focusBooking(booking: Booking): SeatActionTypes {
  return {
    type: FOCUS_BOOKING,
    booking,
  };
}

export function unfocusBooking(): SeatActionTypes {
  return {
    type: UNFOCUS_BOOKING,
  };
}

export function restoreSeatList(
  eventDateId: string | number,
  seatLongIdSet: Set<string> | null,
  errorCallback: () => void
): SeatThunkAction {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (dispatch, getState) => {
    if (!seatLongIdSet) {
      return null;
    }

    return TicketingSdkInstance.getSdk()
      .getRepository('availableSeat')
      .putBulk({
        'hydra:member': seatLongIdSet
          .map((seatLongId): FilteredAvailableSeat => {
            const availableSeat = findAvailableSeatForSeatId(
              getState(),
              seatLongId
            );

            assertRelationIsDefined(availableSeat, 'availableSeat');

            return {
              '@id': availableSeat['@id'],
              '@type': 'AvailableSeat',
              seat: seatLongId,
              status: AVAILABLE_SEAT_STATUS_AVAILABLE,
            };
          })
          .toArray(),
      })
      .then(() => getAvailableSeatListForEventDate(eventDateId))
      .then((availableSeatList) => {
        dispatch({
          type: SET_AVAILABLE_SEAT_LIST,
          availableSeatList,
        });
        dispatch(resetSelectedSeats());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        errorCallback();
      });
  };
}

export function dismissSeatList(
  eventDateId: number,
  seatLongIdSet: Set<string> | null,
  errorCallback: () => void
): SeatThunkAction<void> {
  return (dispatch, getState) => {
    if (!seatLongIdSet) {
      return null;
    }

    return TicketingSdkInstance.getSdk()
      .getRepository('availableSeat')
      .putBulk({
        'hydra:member': seatLongIdSet
          .map((seatLongId): FilteredAvailableSeat => {
            const availableSeat = findAvailableSeatForSeatId(
              getState(),
              seatLongId
            );

            assertRelationIsDefined(availableSeat, 'availableSeat');

            return {
              '@id': availableSeat['@id'],
              '@type': 'AvailableSeat',
              seat: seatLongId,
              status: AVAILABLE_SEAT_STATUS_DISMISSED,
            };
          })
          .toArray(),
      })
      .then(() =>
        getAvailableSeatListForEventDate(eventDateId).then(
          (availableSeatList) => {
            dispatch({
              type: SET_AVAILABLE_SEAT_LIST,
              availableSeatList,
            });
            dispatch(resetSelectedSeats());
          }
        )
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        errorCallback();
      });
  };
}

export function updateContingentSeatList(
  seatContingentMap: Record<string, string>
): SeatActionTypes {
  return {
    type: SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST,
    seatContingentMap,
  };
}

export function addSeatGroup(seatGroup: SeatGroupType): SeatActionTypes {
  return {
    type: ADD_SEAT_GROUP,
    seatGroup,
  };
}

export function addContingent(contingent: Contingent): SeatActionTypes {
  return {
    type: ADD_CONTINGENT,
    contingent,
  };
}

export function removeContingent(contingentId: string): SeatActionTypes {
  return {
    type: REMOVE_CONTINGENT,
    contingentId,
  };
}

export function updateAvailableSeatListSeatGroup(
  availableSeatList: Array<UpdateAvailableSeatListSeatGroupType>
): SeatActionTypes {
  return {
    type: UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP,
    availableSeatList,
  };
}
