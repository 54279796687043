import React, { PureComponent } from 'react';
import { List, Set } from 'immutable';
import { connect } from 'react-redux';
import { getShortId } from '@mapado/js-component';
import { dropdown, MpdToastFunction, useMpdToast } from '@mapado/makeup';
import Hammer from 'hammerjs';
import Seating from '../../../../src/components/Seating';
import MultiSeatSelectedActions from './MultiSeatSelectedActions';
import SidebarTemplate from '../../../../src/components/Sidebar/SidebarTemplate';
import Sidebar from './Sidebar';
import SeatConfigBlockWrapper from './SeatConfigBlockWrapper';
import { disablePan, enablePan } from '../../../../src/utils/svgPanZoom';
import SvgDrawer from '../../../../src/utils/SvgDrawer';
import { TOOL_SELECT_SEAT_FREE_DRAWING } from '../../../../src/components/const';
import findTranslateXY from '../../../../src/components/Main/findTranslateXY';
import DrawButton from '../../../../src/components/Toolbar/DrawButton';
import { initSeatingPlanViewer as initSeatingPlanViewerAction } from '../../../../src/actions/AppActions';
import {
  addSeatConfigBlock as addSeatConfigBlockAction,
  removeSeatConfigBlock as removeSeatConfigBlockAction,
  updateSeatConfigBlockInfo as updateSeatConfigBlockInfoAction,
  createOrUpdateSeatGroup as createOrUpdateSeatGroupAction,
  updateSeatPosition,
} from '../../actions/EditorActions';
import {
  selectBatchOfSeats,
  resetState,
} from '../../../../src/actions/SeatActions';
import '../../../../src/styles/components/App.scss';
import '../../../../src/styles/contexts/editor.css';
import PlanEditorSeat from './PlanEditorSeat';
import { RootState } from '../../../../src/reducers';
import { selectedSeatIdSetSelector } from '../../../../src/utils/selectors';
import {
  SeatConfigType,
  SeatGroupType,
  SeatType,
} from '../../../../src/propTypes';

function setSelectedElement(element: HTMLElement, isSelected: boolean): void {
  if (isSelected) {
    element.classList.add('mpd-seating__editor-element--selected');
  } else {
    element.classList.remove('mpd-seating__editor-element--selected');
  }
}

type State = {
  isDrawing: boolean;
  registeredElements: SVGGElement[];
};

class PlanEditor extends PureComponent<Props, State> {
  _ref: null | HTMLDivElement;

  _seatNodeMap: Record<string, HTMLElement>;

  _seatHammerMap: Record<string, unknown>;

  _svgDrawer: SvgDrawer;

  constructor(props: Props) {
    super(props);
    this.state = {
      registeredElements: [],
      isDrawing: false,
    };

    this.getSeatConfigBlock = this.getSeatConfigBlock.bind(this);
    this.init = this.init.bind(this);
    this.registerNode = this.registerNode.bind(this);
    this.unRegisterNode = this.unRegisterNode.bind(this);
    this.setRef = this.setRef.bind(this);
    this.getMaxPosition = this.getMaxPosition.bind(this);
    this.handleDrawButtonChange = this.handleDrawButtonChange.bind(this);
    this.onClickSeat = this.onClickSeat.bind(this);

    this._ref = null;

    this._seatNodeMap = {};
    this._seatHammerMap = {};

    this._svgDrawer = new SvgDrawer({
      selectBatchOfSeats: this.props.selectBatchOfSeats,
    });
  }

  componentDidMount(): void {
    const { seatConfigId } = this.props;

    if (!seatConfigId) {
      throw new Error('No seatConfigId provided');
    }

    this.init();
    dropdown();
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.state.isDrawing) {
      this._svgDrawer.setMode(TOOL_SELECT_SEAT_FREE_DRAWING);
      this._svgDrawer.handleSelectedSeatIdListChange(
        this.props.selectedSeatIdSet
      );
    } else {
      this._svgDrawer.setMode(null);
    }

    if (
      prevProps.selectedSeatIdSet.size > 0 &&
      this.props.selectedSeatIdSet.size === 0
    ) {
      this._svgDrawer.handleSelectedSeatIdListChange(
        this.props.selectedSeatIdSet
      );
    }
  }

  componentWillUnmount(): void {
    this.props.resetState();
  }

  onClickSeat(
    seatEntity: SeatType,
    isSelected: boolean,
    isSelectable: boolean
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { selectBatchOfSeats } = this.props;

    const seatId = Number(getShortId(seatEntity['@id']));

    if (isSelectable) {
      selectBatchOfSeats([seatId]);
    }
  }

  getMaxPosition(): number {
    const { seatList } = this.props;

    const positionList = seatList.map((s) => s.position).toArray();

    if (positionList.length === 0) {
      return 0;
    }

    return Math.max(...positionList);
  }

  setRef(ref: HTMLDivElement): void {
    if (this._ref === null) {
      this._ref = ref;

      this._svgDrawer.init();

      const seatNodeList = ref.querySelectorAll('.mpd-seating__seat');

      seatNodeList.forEach((seatNode) => {
        const id = seatNode.getAttribute('id');

        if (!id || !(seatNode instanceof HTMLElement)) {
          return;
        }

        this._seatNodeMap[id] = seatNode;

        seatNode.addEventListener('mousedown', disablePan);
        seatNode.addEventListener('mouseup', enablePan);

        let clickEventListener: null | EventListenerOrEventListenerObject =
          null;

        const seatHammer = new Hammer(seatNode);

        seatHammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

        seatHammer.on('panstart', () => {
          seatNode.setAttribute(
            'data-start-pan-transform',
            seatNode.getAttribute('transform') ?? ''
          );

          disablePan();
        });

        seatHammer.on('panend', () => {
          const transformAttribute = seatNode.getAttribute('transform');

          if (!transformAttribute) {
            return;
          }

          const { x, y } = findTranslateXY(transformAttribute);

          this.props.updateSeatPosition(id, x, y, this.props.toast, this.init);

          seatNode.setAttribute(
            'data-start-pan-transform',
            seatNode.getAttribute('transform') ?? ''
          );

          enablePan();
        });

        seatHammer.on('panleft panright panup pandown', (ev) => {
          const { deltaX, deltaY } = ev;

          disablePan();

          /**
           * HammerJS doesn't override the original event listeners
           * So when we finish panning, the click event is triggered
           * But in this case we skip it, so we just use a temporary click listener
           * @param  {[type]} clickEventListener [description]
           * @return {[type]}                    [description]
           */
          if (clickEventListener === null) {
            // @ts-expect-error seem's like a bug as addEventListener returns void, but was here before TS
            clickEventListener = seatNode.addEventListener(
              'click',
              (clickEvent) => {
                clickEvent.preventDefault();
                clickEvent.stopPropagation();

                if (clickEventListener !== null) {
                  seatNode.removeEventListener('click', clickEventListener);
                }
              }
            );
          }

          let transformAttribute = seatNode.getAttribute(
            'data-start-pan-transform'
          );

          if (!transformAttribute) {
            seatNode.setAttribute(
              'data-start-pan-transform',
              seatNode.getAttribute('transform') ?? ''
            );

            transformAttribute = seatNode.getAttribute(
              'data-start-pan-transform'
            );
          }

          if (!transformAttribute) {
            return;
          }

          const { x: currentX, y: currentY } =
            findTranslateXY(transformAttribute);

          const nextX = currentX + deltaX;
          const nextY = currentY + deltaY;

          seatNode.setAttribute('transform', `translate(${nextX}, ${nextY})`);
        });

        this._seatHammerMap[id] = seatHammer;
      });
    }
  }

  getSeatConfigBlock({ id, info }: { id: string; info: string }): JSX.Element {
    return (
      <SeatConfigBlockWrapper
        registerNode={this.registerNode}
        unRegisterNode={this.unRegisterNode}
        id={id}
        info={info}
      />
    );
  }

  init(): void {
    const { seatConfigId, initSeatingPlanViewer } = this.props;

    initSeatingPlanViewer(seatConfigId);
  }

  // eslint-disable-next-line react/sort-comp
  handleDrawButtonChange(isDrawing: boolean): void {
    this.setState({
      isDrawing,
    });
  }

  registerNode(node: SVGGElement): void {
    this.setState((prevState: State) => {
      const registeredElements = prevState.registeredElements.slice(0);

      registeredElements.push(node);

      return {
        registeredElements,
      };
    });
  }

  unRegisterNode(node: SVGGElement): void {
    this.setState((prevState: State) => {
      const registeredElements = prevState.registeredElements.slice(0);
      const index = registeredElements.findIndex((elt) => elt === node);

      if (index >= 0) {
        registeredElements.splice(index, 1);

        return {
          registeredElements,
        };
      }

      return prevState;
    });
  }

  render(): JSX.Element {
    const {
      addSeatConfigBlock,
      toast,
      createOrUpdateSeatGroup,
      removeSeatConfigBlock,
      seatConfig,
      updateSeatConfigBlockInfo,
      seatGroupList,
    } = this.props;
    const { isDrawing, registeredElements } = this.state;

    return (
      <div className="mpd-seating__app context-config" id="mpd-seating__app">
        {seatConfig && (
          <div className="mpd-seating__app__container" ref={this.setRef}>
            <div className="mpd-seating__app__event-svg-wrapper">
              <Seating
                SeatElement={PlanEditorSeat}
                seatEntityType="Seat"
                SeatConfigBlockElement={this.getSeatConfigBlock}
                isMovingSeat={false}
                getSelectableSeatIdSet={(state, seatIdList) => seatIdList}
                onClickSeat={this.onClickSeat}
                seatConfigBlockList={seatConfig.seatConfigBlockList}
                actionButtons={
                  <DrawButton
                    isDrawing={isDrawing}
                    onChange={this.handleDrawButtonChange}
                  />
                }
              />
            </div>

            <SidebarTemplate>
              <Sidebar
                addSeatConfigBlock={addSeatConfigBlock}
                toast={toast}
                createOrUpdateSeatGroup={createOrUpdateSeatGroup}
                registeredElements={registeredElements}
                removeSeatConfigBlock={removeSeatConfigBlock}
                seatConfig={seatConfig}
                seatGroupList={seatGroupList}
                setSelectedElement={setSelectedElement}
                updateSeatConfigBlockInfo={updateSeatConfigBlockInfo}
              >
                <MultiSeatSelectedActions onError={this.init} />
              </Sidebar>
            </SidebarTemplate>
          </div>
        )}
      </div>
    );
  }
}

type PlanEditorProps = {
  seatConfigId: number;
};

type PlanEditorStateProps = {
  seatConfig: SeatConfigType | null;
  seatList: List<SeatType>;
  seatGroupList: List<SeatGroupType>;
  selectedSeatIdSet: Set<number>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DispatchFunction<F extends (...params: any[]) => unknown> = (
  ...params: Parameters<F>
) => void;

type PlanEditorDispatchProps = {
  resetState: typeof resetState;
  addSeatConfigBlock: DispatchFunction<typeof addSeatConfigBlockAction>;
  createOrUpdateSeatGroup: DispatchFunction<
    typeof createOrUpdateSeatGroupAction
  >;
  initSeatingPlanViewer: DispatchFunction<typeof initSeatingPlanViewerAction>;
  removeSeatConfigBlock: DispatchFunction<typeof removeSeatConfigBlockAction>;
  updateSeatConfigBlockInfo: DispatchFunction<
    typeof updateSeatConfigBlockInfoAction
  >;
  updateSeatPosition: DispatchFunction<typeof updateSeatPosition>;
  selectBatchOfSeats: DispatchFunction<typeof selectBatchOfSeats>;
};

type Props = {
  toast: MpdToastFunction;
} & PlanEditorProps &
  PlanEditorStateProps &
  PlanEditorDispatchProps;

function PlanEditorWithToast(
  props: PlanEditorProps & PlanEditorStateProps & PlanEditorDispatchProps
): JSX.Element {
  const toast = useMpdToast();

  return <PlanEditor {...props} toast={toast} />;
}

const mapStateToProps = (state: RootState): PlanEditorStateProps => ({
  seatConfig: state.seating.get('seatConfig'),
  seatList: state.seating.get('seatList') ?? List(),
  seatGroupList: state.seating.get('seatGroupList') ?? List(),
  selectedSeatIdSet: selectedSeatIdSetSelector(state) ?? Set(),
});

const mapDispatchToProps = {
  resetState,
  addSeatConfigBlock: addSeatConfigBlockAction,
  createOrUpdateSeatGroup: createOrUpdateSeatGroupAction,
  initSeatingPlanViewer: initSeatingPlanViewerAction,
  removeSeatConfigBlock: removeSeatConfigBlockAction,
  updateSeatConfigBlockInfo: updateSeatConfigBlockInfoAction,
  updateSeatPosition,
  selectBatchOfSeats,
};

export default connect<
  PlanEditorStateProps,
  PlanEditorDispatchProps,
  PlanEditorProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(PlanEditorWithToast);
